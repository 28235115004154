var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "client-details",
    class: {
      visible: _vm.detailsVisible()
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "5"
    }
  }, [_c('company-details-card', {
    attrs: {
      "type": "clients"
    }
  })], 1), _c('b-col', {
    directives: [{
      name: "can",
      rawName: "v-can:index",
      value: 'crm.company_address_contact',
      expression: "'crm.company_address_contact'",
      arg: "index"
    }],
    staticClass: "card-column",
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "7"
    }
  }, [_c('company-addresses-card')], 1), _c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "6"
    }
  }, [_vm.company.files ? _c('company-attachments-card', {
    attrs: {
      "files": _vm.company.files
    }
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('company-notes-card')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }